$main-color:#ffd700;

.contact-form {
    width: 100%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      

      &::placeholder{
        letter-spacing: 1px;
      }
    
    }

  
    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      resize: none;
      
      &::placeholder{
        letter-spacing: 1px;
      }



    }
  
    .flat-button{
      color: $main-color;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid $main-color;
      float: left;
      animation: fadeIn 1s 1.8s backwards;
      white-space: nowrap;
      margin-top: 10px;
      background: transparent;
      cursor: pointer;

      &:hover{
          background: $main-color;
          color: #333;
      }
  }
}

.alert-container{
  position: fixed;
  top: -0;
  left: 0;
  background: rgba(0, 0, 0, 0.795);
  height: 100vh;
  width: 100%;
  z-index: 100;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  

  .msg-container{
    font-family: 'Helvetica';
    background: #115173;
    margin: 0 2rem;
    padding: 1.5rem 1.2rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    max-width: 40rem;

    .message-box{
      font-weight: 200;
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.5;
      color: #f8f8f8;
    }

    .btn{
      background: none;
      border: 1px solid $main-color;
      color: $main-color;
      text-transform: uppercase;
      letter-spacing: 2px;
      align-self: flex-end;
      padding: .5rem 1rem;
      cursor: pointer;

      &:hover{
        background: $main-color;
        color: #333;
      }

    }

  }
  
}

.alert-container.open{
  display: flex;
}


