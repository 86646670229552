$primary-color:#ffd700;

@import 'animate.css';
// @import '~loaders.css/src/animations/pacman.scss';

@font-face{
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}
@font-face{
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}
@font-face{
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,textarea{
  font-family: 'Coolvetica';
}

.tails{
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}