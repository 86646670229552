$active-color : #ffd700;

.nav-bar{
    background: #181818;
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo{
        display: block;
        padding: 8px 0;
    }

    img{
        display: block;
        margin: 8px auto;
        width: 24px;
        height: auto;

        &.sub-logo{
            width: 50px;
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;
    
        a {
          font-size: 22px;
          color: #4d4d4e;
          display: block;
          line-height: 51px;
          height: 51px;
          position: relative;
          text-decoration: none;
    
          i {
            transition: all 0.3s ease-out;
          }
    
          &:hover {
            color: $active-color;
    
            svg {
              opacity: 0;
            }
    
            &:after {
              opacity: 1;
            }
          }
    
          &:after {
            content: '';
            font-size: 9px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }
    
          &:first-child {
            &:after {
              content: 'HOME';
            }
          }
        }
    
        a.about-link {
          &:after {
            content: 'ABOUT';
          }
        }
    
        a.contact-link {
          &:after {
            content: 'CONTACT';
          }
        }
    
        a.portfolio-link {
          &:after {
            content: 'PORTFOLIO';
          }
        }
    
        a.active {
          svg {
            color:$active-color;
          }
        }
      }
    ul{
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;
        li{
            a{
                padding: 8px 0;
                display: block;
                font-size: 16px;
                line-height: 16px; 

                &:hover svg{
                    color: $active-color;
                }
            }
        }
    }
}
.hamburger-icon,.close-icon{
  display: none;
}

@media screen and (max-width: 1100px) {
  .nav-bar{
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    nav,ul{
      display: none;
    }

  
    
    nav{
      // change 1
      width: 100%;
      height: 70%;
      background: #181818;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 2;
      margin: 0;
      justify-content: center;
      
      .link-holder{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: center;
        align-items: right;
        text-align: center;
      }
    
      a{
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 3rem;
      }
  
      a.active {
        svg {
          color:$active-color;
        }
        &::after{
          color: $active-color;
        }
      }

      a:after{
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
        font-size: 14px;
      }

      a svg{
        opacity: 1 !important;
      }

      &.mobile-show{
        display: flex;
      }
    }
    ul{
      position: fixed;
      width: 100%;
      height: 30%;
      bottom: 0;
      z-index: 1;
      gap: 6rem;
      justify-content: center;
      align-items: flex-start;
      background: #181818;

      li{
        a{
          margin-top: 6rem;
          font-size: 26px;
        }
      }

      &.mobile-show{
        display: flex;
      } 
    }
    .hamburger-icon,.close-icon{
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

}