.logo-container{
    z-index: -1;
    width: 400px;
    height: 689px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: 0 auto;


    .solid-logo{
        position: absolute;
        top: 100px;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        // opacity: 0;
        transform: rotateZ('45deg');
    }
}